import React, { useState, useRef } from 'react';
import './App.css';

function App() {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [summary, setSummary] = useState("");


  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      const audioChunks = [];
      mediaRecorderRef.current.ondataavailable = (e) => {
        audioChunks.push(e.data);
      };
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunks, { 'type' : 'audio/mp4' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        // Reset transcription and summary on new recording
        setTranscription("");
        setSummary("");
      };
      mediaRecorderRef.current.start();
      setIsRecording(true);
      // Reset audioUrl, transcription, and summary on start
      setAudioUrl(null);
      setTranscription("");
      setSummary("");
    } else {
      alert('Audio recording is not supported in this browser.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const sendAudio = async () => {
    if (audioUrl) {
      const formData = new FormData();
      formData.append('audio', await fetch(audioUrl).then(r => r.blob()), 'audio.mp4');

      // Transcribe audio
      let response = await fetch('http://127.0.0.1:5000/transcribe', {
        method: 'POST',
        body: formData,
      });
      const transcriptionData = await response.json();
      const transcriptionText = transcriptionData.join("\n");
      setTranscription(transcriptionText); // Update transcription state

      // Summarize transcription
      response = await fetch('http://127.0.0.1:5000/summarize', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ transcription: transcriptionText }),
      });
      const summaryData = await response.json();
      setSummary(summaryData.summary); // Update summary state
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        {isRecording ? (
          <button onClick={stopRecording}>Stop Recording</button>
        ) : (
          <button onClick={startRecording}>Start Recording</button>
        )}
        <button onClick={sendAudio} disabled={!audioUrl}>Send Audio</button>
      </header>
      <main>
        {audioUrl && <audio src={audioUrl} controls />}
        {transcription && (
          <div className="card">
            <h2>Transcription:</h2>
            <p>{transcription}</p>
          </div>
        )}
        {summary && (
          <div className="card">
            <h2>Summary:</h2>
            <p>{summary}</p>
          </div>
        )}
      </main>
    </div>
  );
}

export default App;